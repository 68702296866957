import React, {useState} from 'react'
import SideBar from '../components/SideBar'
import NavBar from '../components/NavBar'
import {
    ServicesSection1,
    ServicesSection2,
    ServicesSection3,
    ServicesSection4,
    ServicesSection5
} from '../components/ServicesSection/ServicesSections'
import {
    serviceObjOne,
    serviceObjTwo,
    serviceObjThree,
    serviceObjFour,
    serviceObjFive
} from '../components/ServicesSection/data'

const Services = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
          <SideBar isOpen={isOpen} toggle={toggle} />
          <NavBar toggle={toggle} />
          <ServicesSection1 {...serviceObjOne} />
          <ServicesSection2 {...serviceObjTwo} />
          <ServicesSection3 {...serviceObjThree} />
          <ServicesSection4 {...serviceObjFour} />
          <ServicesSection5 {...serviceObjFive} />
        </>
    )
}

export default Services