import React, {useState} from 'react'
import SideBar from '../components/SideBar'
import NavBar from '../components/NavBar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import InfoSection2 from '../components/InfoSection2'
import { homeObjOne } from '../components/InfoSection/data'
import { homeObjTwo } from '../components/InfoSection2/data'


const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
          <SideBar isOpen={isOpen} toggle={toggle} />
          <NavBar toggle={toggle} />
          <HeroSection />
          <InfoSection {...homeObjOne}/>
          <InfoSection2 {...homeObjTwo}/>
        </>
    )
}

export default Home