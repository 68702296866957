import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages'
import Services from './pages/services'
import About from './pages/about'
import Contact from './pages/contact'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/services' element={<Services />} exact />
        <Route path='/about' element={<About />} exact />
        <Route path='/contact_us' element={<Contact />} exact />
      </Routes>
    </Router>
  );
}

export default App;
