export const serviceObjOne = {
    id: 'osint',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Open-Source Intelligence Gathering',
    headline: 'Understand your overall attack surface',
    // eslint-disable-next-line
    description: 'In today’s ever-growing online world, the online presence of an organization \
     or even an individual is critical for the image and reputation of the brand. Open-Source \
     Intelligence can also be leveraged by threat actors in their attacks against their targets. \
     This exercise provides a snapshot in time view and analysis of the information that is out \
     on the web, not only the public surface web but the less-visited and criminally prevalent \
     dark web as well. Analysis will include how the information could be leveraged in an attack \
     and the potential impact.',
    imgStart: false,
    dark: true,
    primary: true,
    darkText: false
}

export const serviceObjTwo = {
    id: 'vulnAsses',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Vulnerability Assessments',
    headline: 'Find vulnerabilities and prioritize remediation efforts',
    // eslint-disable-next-line
    description: 'Missing security updates or incorrect configurations can result in vulnerabilities \
     that a threat actor can exploit to gain access or further an attack against a target. These are \
     often overlooked in even mature information security programs. Vulnerability assessments provide \
     the information to drive remediation efforts with not only the detection of the vulnerabilities \
     across an estate but the prioritization of remediation based on risk.',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: false
}

export const serviceObjThree = {
    id: 'penTest',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Penetration Testing',
    headline: 'Test your security program against know attacker tactics',
    // eslint-disable-next-line
    description: 'Missing security updates or incorrect configurations can result in vulnerabilities \
     that a threat actor can exploit to gain access or further an attack against a target. These are \
     often overlooked in even mature information security programs. Vulnerability assessments provide \
     the information to drive remediation efforts with not only the detection of the vulnerabilities \
     across an estate but the prioritization of remediation based on risk.',
    imgStart: false,
    dark: true,
    primary: true,
    darkText: false
}

export const serviceObjFour = {
    id: 'cloudSecAudit',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Cloud Security Audits',
    headline: 'How do you compare to industry best practices?',
    // eslint-disable-next-line
    description: 'With more and more organizations adopting a public or hybrid cloud model, the days \
    of comfort from perimeter security are a thing of the past. As the integration with cloud services \
    accelerates and widens, so does the attack surface. Each service represents a new attack vector or \
    entry point to a target. Auditing the security settings of these services against industry best \
    practices allows for the detection of gaps and the development of a remediation roadmap.',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: false
}

export const serviceObjFive = {
    id: 'compliance',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Regulatory Compliance Audits',
    headline: 'Are you compliant?',
    // eslint-disable-next-line
    description: 'With a no-compromise approach regarding compliance, whether it is HIPPA, PCI, SEC, \
    or any other regulatory body, we ensure that your business’s Information Security Program will meet \
    the requirements, and develop an in-depth road map to close any gaps in order to do so in a timely manner.',
    imgStart: false,
    dark: true,
    primary: true,
    darkText: false
}