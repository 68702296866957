import React from 'react'
import {
    AboutUsContainer
} from './AboutUsElements'

const AboutUsSection = () => {
    return (
        <AboutUsContainer>

        </AboutUsContainer>
    )
}

export default AboutUsSection