import React, { useRef} from 'react'
import emailjs from '@emailjs/browser'

import {
    ContactContainer,
    ContactWrapper,
    ContactRow,
    Column1,
    Column2,
    ImgWrap,
    Img,
    TextWrapper,
    TopLine,
} from './ContactComponents'

const ContactSection = () => {

    const [formStatus, setFormStatus] = React.useState('Send')
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');
        emailjs.sendForm('service_5frdrrb', 'template_p010x7c', form.current, 'upm5nejPrq-27Cvex')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
                setFormStatus('Submitted!');
                alert('Your request has been submitted. Someone will be reaching out with more info soon. Thank you for your interest in Barrier 1\'s services.')
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <ContactContainer>
            <ContactWrapper>
                <ContactRow>
                    <Column1>
                        <ImgWrap>
                            <Img src={require('./contact_us.jpeg')} alt='about_us' />
                        </ImgWrap>

                    </Column1>
                    <Column2>
                        <TextWrapper>
                            <TopLine>Contact US</TopLine>
                            <p>For further information about us and our services, please use the form below or send an email to <a href="mailto:Info@b1sec.com?subject='Request for Info'&body='We would like to request more information about the Barrier 1 Services.'">Info@B1Sec.com</a></p>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">
                                        Name
                                    </label>
                                    <input className="form-control" type="text" id="name" name="from_name" required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">
                                        Email
                                    </label>
                                    <input className="form-control" type="email" id="email" name="reply_to" required />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="message">
                                        Message
                                    </label>
                                    <textarea className="form-control" id="message" name="message" required />
                                </div>
                                <button className="btn btn-danger" type="submit">
                                    {formStatus}
                                </button>
                            </form>
                        </TextWrapper>
                    </Column2>
                </ContactRow>
            </ContactWrapper>
        </ContactContainer>
    )
}

export default ContactSection