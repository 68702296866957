import React from 'react'
import {FaBars} from 'react-icons/fa'
import {
    Nav, 
    NavBarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    NavP} from './NavBarElements'
import "../../images/B1LogoMain.svg"

const logo = require('../../images/B1LogoMain.svg').default

const Navbar = ({ toggle }) => {
    return (
        <>
          <Nav>
              <NavBarContainer>
                    <NavLogo to='/'>
                        <img src={logo} width="300" height="75" alt="Barrier 1 Digital Security" />
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="/services">Services</NavLinks>
                        </NavItem>
                        <NavItem>
                        <NavLinks to="/contact_us">Contact Us</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavP>Follow Us </NavP>
                        </NavItem>
                        <NavItem>
                            <NavP>
                                <a 
                                    href='https://twitter.com/Barrier_1_Sec' 
                                    target="_blank" 
                                    rel='noreferrer'
                                    >
                                    <img src="../../twitter.svg" height='30' width='30' alt='twitter_logo'/>
                                </a>
                            </NavP>
                        </NavItem>
                        <NavItem>
                            <NavP>
                                <a 
                                    href='https://instagram.com/barrier_1_digital_security?igshid=YmMyMTA2M2Y=' 
                                    target="_blank" 
                                    rel='noreferrer'
                                    >
                                    <img src="../../instagram.svg" height='30' width='30' alt='instagram_logo'/>
                                </a>
                            </NavP>
                        </NavItem>
                  </NavMenu>
              </NavBarContainer>
          </Nav>
        </>
    )
}

export default Navbar