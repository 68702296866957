import styled from "styled-components";

export const AboutUsContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  padding: 0 30px;
  height: 855px;
  position: relative;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg, 
      rgba(0,0,0,0.2) 0%,
      rgba(0,0,0.6) 100%), 
      linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 2;
  }
`