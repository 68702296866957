import Video from '../../videos/Main.mov'
import {
    HeroContainer,
    HeroBG,
    VideoBG,
    HeroContent,
    HeroP,
    Img
} from './HeroSectionElements'

const logo = require('../../images/B1LogoMain.svg').default

const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroBG>
                <VideoBG autoPlay loop muted src={Video} type='video/mov' />
            </HeroBG>
            <HeroContent>
                <Img src={logo} alt="Barrier 1 Digital Security"/>
                <HeroP>
                    Helping you secure the assets that matter most
                </HeroP>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
