export const homeObjOne = {
    id: 'about_us',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'Who We Are',
    // eslint-disable-next-line
    description: 'With over 40 years of experience in Information Technology, \
    over 12 of that being dedicated to Information Security, Barrier 1 aims \
    to deliver the same standards of security demanded by some of the leading \
    financial institutions in the world to all sectors. With members with \
    connections to Cyber Task Forces, Conferences, Competitions, and deep \
    connections to the InfoSec community as a whole, the Barrier 1 Digital \
    Security team has a finger on the pulse of the threat landscape and emerging \
    trends in the field.',
    imgStart: true,
    dark: true,
    primary: true,
    darkText: false
}



