export const homeObjTwo = {
    id: 'general_services',
    lightBg: false,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Services',
    headline: 'The B1Sec Approach',
    // eslint-disable-next-line
    description: 'Whether you are an individual with a larger than average \
    online presence, an organization looking to meet regulatory requirements, \
    or a business looking to ensure their cybersecurity posture, Barrier 1 \
    Digital Security can meet your needs. With a wide range of products and \
    services, we provide solutions for all aspects of Information Security.',
    imgStart: false,
    dark: true,
    primary: true,
    darkText: false
}