import React from 'react'
import { 
    SideBarContainer, 
    Icon, CloseIcon, 
    SideBarBtnWraper, 
    SideBarMenu,
    SideBarWrapper, 
    SideBarRoute} from './SideBarElements'

const SideBar = ({ isOpen, toggle}) => {
    return (
        <SideBarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SideBarWrapper>
                <SideBarBtnWraper>
                    <SideBarRoute to="/services" onClick={toggle}>Services</SideBarRoute>
                </SideBarBtnWraper>
                <br />
                <SideBarBtnWraper>
                    <SideBarRoute to="/contact_us">Contact Us</SideBarRoute>
                </SideBarBtnWraper>
                <br />
                <SideBarMenu>
                    <a 
                        href='https://instagram.com/barrier_1_digital_security?igshid=YmMyMTA2M2Y=' 
                        target="_blank" 
                        rel='noreferrer'
                        >
                        <img src="../../instagram.svg" height='30' width='30' alt='instagram_logo'/>
                    </a>
                    <a 
                        href='https://twitter.com/Barrier_1_Sec' 
                        target="_blank" 
                        rel='noreferrer'
                        >      
                        <img src="../../twitter.svg" height='30' width='30' alt='twitter_logo'/>
                    </a>
                </SideBarMenu>
            </SideBarWrapper>
        </SideBarContainer>
    )
}

export default SideBar
